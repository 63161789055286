.anime {
	background: linear-gradient(-45deg, #212121, #424242, #000000);
	background-size: 400% 400%;
	animation: gradientBG 30s ease infinite;
	width: 100%;
	position: relative;
	text-align: center;
	height: 280px;
	color: white;
}

@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
